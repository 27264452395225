import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default function Footer() {
  return (
    <Box pt={4}>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://ascentregtech.com">
          Ascent
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}
