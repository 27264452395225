import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormDialog from '../../FormDialog';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

import useForm from '../../../hooks/useForm';
import {
  ADD_PUBLICATION_MUTATION,
  PUBLICATIONS_QUERY,
  ACTIVE_PUBLICATIONS_QUERY,
  ACTIVE_VENDORS_QUERY,
} from '../queries';

const WARNING_REGEX = /Record not created since (.*) exists/i;

function AddPublication({ authorId, showByDefault = false }) {
  const [isOpen, setIsOpen] = useState(showByDefault);
  const [allowOverride, setAllowOverride] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const history = useHistory();
  const defaults = {
    vendorId: '',
    name: '',
    externalId: '',
  };

  const { props, values, errors, warnings, reset, setErrors, setWarnings } = useForm(defaults);

  const refetchQueries = [
    { query: PUBLICATIONS_QUERY, variables: { authorId } },
    { query: ACTIVE_PUBLICATIONS_QUERY },
  ];
  const [addPublication] = useMutation(ADD_PUBLICATION_MUTATION, { refetchQueries });
  const { loading, error, data: vendorsData } = useQuery(ACTIVE_VENDORS_QUERY);

  function handleRedirect(publication) {
    setRedirecting(true);
    setTimeout(() => history.push(`/publications/${publication.id}`), 500);
  }

  const resetUserAlerts = () => {
    setWarnings([]);
    setErrors([]);
    setAllowOverride(false);
  };

  const handleClose = () => {
    reset();
    setIsOpen(false);
    setAllowOverride(false);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const {
      data: {
        createPublication: { errors, publication },
      },
    } = await addPublication({
      variables: {
        authorId: authorId,
        vendorId: values.vendorId,
        name: values.name,
        externalId: values.externalId,
        force: allowOverride,
      },
    });

    if (errors.length > 0) return handleErrors(errors);
    handleRedirect(publication);
  };

  const handleErrors = (errData) => {
    const [warnings, formErrors] = errData.reduce(
      ([warns, errs], current) =>
        current.match(WARNING_REGEX) ? [[...warns, current], errs] : [warns, [...errs, current]],
      [[], []]
    );

    setAllowOverride(warnings.length > 0);
    setWarnings(warnings);
    setErrors(formErrors);
  };

  const handleDialogFormChange = () => {
    // remove validation alerts of they exist after a change
    if (errors.length || warnings.length) return resetUserAlerts();
  };

  if (loading) return <LinearProgress data-testid="loading" />;
  if (error) return handleErrors(error);

  return (
    <>
      <Button data-testid="add-pub-btn" variant="outlined" onClick={() => setIsOpen(!isOpen)}>
        + Add Publication
      </Button>
      <FormDialog
        allowSubmit={!!(values.vendorId && values.name && values.externalId)}
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitText={`Add Publication ${allowOverride ? `Anyway` : ''}`}
        title={'Add A Publication'}
        errors={errors}
        onChange={handleDialogFormChange}
        warnings={warnings}
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel id="vendor-select-label">Vendor</InputLabel>
          <Select
            required
            labelId="vendor-select-label"
            data-testid="vendor-select"
            name="vendorId"
            label="Vendor"
            value={values.vendorId}
            {...props.vendorId}
          >
            {vendorsData.vendors.map((v, idx) => (
              <MenuItem
                data-testid={`vendor-option-${idx}`}
                key={`section-option-${v.id}`}
                value={v.id}
              >
                {v.prettyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label={'Publication Name'}
          margin="normal"
          type="text"
          variant="outlined"
          value={values.name}
          {...props.name}
        />
        <TextField
          fullWidth
          label={'External ID'}
          margin="normal"
          type="text"
          variant="outlined"
          value={values.externalId}
          {...props.externalId}
        />

        {redirecting && `Redirecting...`}
      </FormDialog>
    </>
  );
}

AddPublication.propTypes = {
  authorId: PropTypes.string.isRequired,
};

export default AddPublication;
