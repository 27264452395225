import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

const FormDialog = ({
  allowSubmit,
  children,
  errors = [],
  isOpen = false,
  onClose,
  onSubmit,
  submitText = 'Submit',
  title,
  warnings = [],
  onChange,
  ...rest
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="dialog-title" {...rest}>
      <form data-testid="form" onSubmit={onSubmit} onChange={onChange} {...rest}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {children}
          {errors.map((e, idx) => (
            <Alert key={idx} children={e} />
          ))}
          {warnings.map((w, idx) => (
            <Alert key={idx} children={w} severity="warning" />
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            role="button"
            data-testid="btn-submit"
            disabled={!allowSubmit}
          >
            {submitText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

FormDialog.propTypes = {
  allowSubmit: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  errors: PropTypes.arrayOf(PropTypes.string),
  warnings: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default FormDialog;
