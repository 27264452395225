import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';

import FormDialog from '../../FormDialog';
import useForm from '../../../hooks/useForm';
import { ADD_AUTHOR_MUTATION, AUTHORS_QUERY } from '../queries';

const WARNING_REGEX = /Record not created since (.*) exists/i;

function AddAuthor({ showByDefault = false }) {
  const [isOpen, setIsOpen] = useState(showByDefault);
  const [allowOverride, setAllowOverride] = useState(false);
  const [isRedirecting, setRedirecting] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const history = useHistory();
  const defaults = {
    name: '',
    externalId: '',
  };

  const { props, values, errors, warnings, reset, setWarnings, setErrors } = useForm(defaults);

  const refetchQueries = () => [{ query: AUTHORS_QUERY }];
  const [addAuthor] = useMutation(ADD_AUTHOR_MUTATION, { refetchQueries });

  function handleSuccess(author) {
    setLoading(false);
    setRedirecting(true);
    setTimeout(() => history.push(`/authors/${author.id}`), 500);
  }

  const resetUserAlerts = () => {
    setWarnings([]);
    setErrors([]);
    setAllowOverride(false);
  };
  const handleClose = () => {
    resetUserAlerts();
    reset();
    setIsOpen(false);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const {
      data: {
        createAuthor: { errors, author },
      },
    } = await addAuthor({
      variables: {
        name: values.name,
        externalId: values.externalId,
        force: allowOverride,
      },
    });

    if (errors.length > 0) return handleErrors(errors);
    handleSuccess(author);
  };

  const handleErrors = (errData) => {
    const [warnings, formErrors] = errData.reduce(
      ([warn, error], current) =>
        current.match(WARNING_REGEX) ? [[...warn, current], error] : [warn, [...error, current]],
      [[], []]
    );

    setAllowOverride(warnings.length > 0);
    setWarnings(warnings);
    setErrors(formErrors);
  };

  return (
    <>
      <Button data-testid="add-author-btn" variant="outlined" onClick={() => setIsOpen(!isOpen)}>
        + Add An Author
      </Button>
      <FormDialog
        allowSubmit={!!(values.name && values.externalId)}
        isOpen={isOpen}
        onClose={handleClose}
        onChange={() => {
          if (errors.length || warnings.length) return resetUserAlerts();
        }}
        onSubmit={handleSubmit}
        submitText={`Add Author ${allowOverride ? `Anyway` : ''}`}
        title={'Add an Author'}
        errors={errors}
        warnings={warnings}
      >
        <TextField
          fullWidth
          label={'Author Name'}
          margin="normal"
          variant="outlined"
          {...props.name}
        />
        <TextField
          fullWidth
          label={'External ID'}
          margin="normal"
          variant="outlined"
          {...props.externalId}
        />
        {isLoading && <LinearProgress data-testid="loading" />}
        {isRedirecting && `Redirecting...`}
      </FormDialog>
    </>
  );
}

export default AddAuthor;
