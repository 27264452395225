import React from 'react';
import { Switch, Route, useLocation, useRouteMatch, useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import { AUTHOR_QUERY } from '../queries';
import AuthorMore from '../AuthorMore';
import PublicationsWithData from '../../Publications/Publications';
import AuthorDetails from '../AuthorDetails';

const useStyles = makeStyles((theme) => ({
  topBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const AuthorPage = () => {
  const classes = useStyles();
  const { authorId } = useParams();
  const location = useLocation();
  const match = useRouteMatch();

  const { loading, error, data } = useQuery(AUTHOR_QUERY, {
    variables: { id: authorId },
  });

  const indexUrl = match.url;
  const indexPath = match.path;
  const publicationsUrl = `${match.url}/publications`;
  const publicationsPath = `${match.path}/publications`;

  if (loading) {
    const waitMessage = 'This might take a bit, hang tight...';

    return (
      <>
        <Typography component="p">{waitMessage}</Typography>
        <LinearProgress data-testid="loading" />
      </>
    );
  }

  if (error) return <Alert severity="error">{JSON.stringify(error)}</Alert>;

  const {
    author: { name: authorName },
  } = data;

  return (
    <Container maxWidth="lg">
      <Box className={classes.topBox}>
        <Typography variant="h3" component="h1" children={authorName} />

        <AuthorMore authorId={authorId} authorName={authorName} />
      </Box>

      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Dashboard</Link>
        <Link to="/authors">Authors</Link>
        <Typography color="textPrimary">{authorName}</Typography>
      </Breadcrumbs>
      <Tabs aria-label="Autor Detail tabs" value={location.pathname}>
        <Tab value={indexUrl} label="Details" component={Link} to={indexUrl} />
        <Tab value={publicationsUrl} label="Publications" component={Link} to={publicationsUrl} />
      </Tabs>
      <Switch>
        <Route path={indexPath} exact>
          <AuthorDetails authorId={authorId} />
        </Route>
        <Route path={publicationsPath}>
          <PublicationsWithData authorId={authorId} />
        </Route>
      </Switch>
    </Container>
  );
};

export default AuthorPage;
