import { useState, useEffect } from 'react';

export const makeValue = (target) => (target.type === 'checkbox' ? target.checked : target.value);

export const useForm = (defaultValues) => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  const onChange = ({ target }) => {
    setValues((currentState) => ({
      ...currentState,
      [target.name]: makeValue(target),
    }));
    setHasChanges(true);
  };

  const propsFromValues = (values, onChange) =>
    Object.entries(values).reduce(
      (acc, [name]) => ({
        ...acc,
        [name]: {
          name,
          onChange,
          inputProps: { 'data-testid': `input-${name}` },
        },
      }),
      {}
    );

  const defaultProps = propsFromValues(defaultValues, onChange);

  const [props, setProps] = useState(defaultProps);

  const reset = () => {
    setValues(defaultValues);
    setHasChanges(false);
    setErrors([]);
    setWarnings([]);
  };

  useEffect(() => setProps(propsFromValues(values, onChange)), [values]);

  return {
    props,
    values,
    warnings,
    errors,
    hasChanges,
    setErrors,
    setWarnings,
    setHasChanges,
    reset,
  };
};

export default useForm;
