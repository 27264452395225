import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import Chip from '@material-ui/core/Chip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  dateCell: {
    whiteSpace: 'nowrap',
  },
  green: {
    color: '#fff',
    backgroundColor: '#4caf4f',
  },
  gray: {
    color: '#fff',
    backgroundColor: '#757575',
  },
  orange: {
    color: '#fff',
    backgroundColor: '#f47c05',
  },
});

const PUB_STATUSES = {
  DISABLED: {
    label: 'Disabled',
    className: 'gray',
  },
  COLLECT_ONLY: {
    label: 'Collect Only',
    className: 'orange',
  },
  COLLECT_AND_DEPLOY: {
    label: 'Collect & Deploy',
    className: 'green',
  },
};

const AUTHOR_STATUSES = {
  DISABLED: {
    label: 'Disabled',
    className: 'gray',
  },
  ENABLED: {
    label: 'Enabled',
    className: 'green',
  },
};

export default function PublicationsTable({ publications, showAuthor = false }) {
  const classes = useStyles();
  const nullValue = '--';

  return (
    <TableContainer>
      <Table aria-label="simple table" data-testid="PublicationsTable">
        <TableHead>
          <TableRow>
            {showAuthor && <TableCell align="center">Author Status</TableCell>}
            {showAuthor && <TableCell>Author</TableCell>}
            <TableCell align="center">Pub Status</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Vendor</TableCell>
            <TableCell align="right">Last Collected</TableCell>
            <TableCell align="right">Last Deployed</TableCell>
            <TableCell align="right">Deployed Docs</TableCell>
            <TableCell align="right">Total Docs</TableCell>
            <TableCell align="center">Test Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {publications.map((publication) => {
            const pubStatus = PUB_STATUSES[publication.status];
            const authorStatus = AUTHOR_STATUSES[publication.author.status];
            const statistics = publication.statistics || {};

            return (
              <TableRow key={publication.id}>
                {showAuthor && (
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={authorStatus.label}
                      className={classes[authorStatus.className]}
                    />
                  </TableCell>
                )}
                {showAuthor && (
                  <TableCell>
                    <Link to={`/authors/${publication.author.id}`}>{publication.author.name}</Link>
                  </TableCell>
                )}
                <TableCell align="center">
                  <Chip
                    size="small"
                    label={pubStatus.label}
                    className={classes[pubStatus.className]}
                  />
                </TableCell>
                <TableCell>
                  <Link to={`/publications/${publication.id}`}>{publication.name}</Link>
                </TableCell>
                <TableCell>{publication.vendor.prettyName}</TableCell>
                <TableCell align="right" className={classes.dateCell}>
                  {statistics.lastCollectedDate || nullValue}
                </TableCell>
                <TableCell align="right" className={classes.dateCell}>
                  {statistics.lastDeployedDate || nullValue}
                </TableCell>
                <TableCell align="right">
                  {statistics.documentsDeployedCount || nullValue}
                </TableCell>
                <TableCell align="right">{statistics.documentsCount || nullValue}</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
