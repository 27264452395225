import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { LinearProgress } from '@material-ui/core';

import { Publications } from '../Publications';
import { ACTIVE_PUBLICATIONS_QUERY } from '../queries';
import { compactObject } from '../../../utils/object';
import { cursorToPage, pageToCursor, useLocationSearch } from '../../../utils/searchParams';

export function PublicationsPage({ publications, paginationProps, alphaFiltersProps }) {
  return (
    <Container maxWidth="xl">
      <Typography variant="h3" component="h1" children="Active Publications" />
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Dashboard</Link>
        <Typography color="textPrimary">Active Publications</Typography>
      </Breadcrumbs>
      <Publications
        publications={publications}
        showAuthor={true}
        paginationProps={paginationProps}
        alphaFiltersProps={alphaFiltersProps}
      />
    </Container>
  );
}

export default function PublicationsPageWithData() {
  const [currentTab, setCurrentTab] = useState('ALL');

  const {
    params: { after, prefix },
    replaceSearchParams,
  } = useLocationSearch();

  const { loading, error, data } = useQuery(ACTIVE_PUBLICATIONS_QUERY, {
    variables: compactObject({ after, prefix }),
  });

  if (loading)
    return (
      <>
        <Typography>This might take a bit, hang tight...</Typography>
        <LinearProgress data-testid="loading" />
      </>
    );

  if (error) return <Alert severity="error">{JSON.stringify(error)}</Alert>;

  const { nodes: activePublications, pageSize, pageCount } = data.activePublications;

  const page = cursorToPage(after, pageSize);

  const setPage = (newPage) => {
    const newAfter = pageToCursor(newPage, pageSize);

    replaceSearchParams(compactObject({ after: newAfter, prefix: prefix }));
  };

  const handleTabChange = (_, tabval) => {
    replaceSearchParams(compactObject({ prefix: tabval === 'ALL' ? undefined : tabval }));
    setCurrentTab(tabval);
  };

  const paginationProps = { page, pageCount, setPage };
  const alphaFiltersProps = { currentTab, onChange: handleTabChange };

  return (
    <PublicationsPage
      publications={activePublications}
      paginationProps={paginationProps}
      alphaFiltersProps={alphaFiltersProps}
    />
  );
}
