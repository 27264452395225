import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { LinearProgress } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import AddPublication from '../AddPublication';
import AlphaTabFilters from '../../AlphaTabFilters';
import PublicationsTable from '../PublicationsTable';

import { PUBLICATIONS_QUERY } from '../queries';

const useStyles = makeStyles({
  addPubBox: {
    display: 'flex',
    marginBottom: '2rem',
    justifyContent: 'flex-end',
  },
});

const PaginationComponent = ({ page, pageCount, setPage, ...rest }) => {
  const onPageClick = (_event, page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  return (
    <Box display="flex" justifyContent="center" {...rest}>
      <Pagination
        data-testid="pagination-controls"
        count={pageCount}
        onChange={onPageClick}
        page={page}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};

export const Publications = ({
  authorId,
  publications,
  showAuthor = false,
  paginationProps = undefined,
  alphaFiltersProps = undefined,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        {authorId && (
          <Box className={classes.addPubBox}>
            <AddPublication authorId={authorId} />
          </Box>
        )}
        {alphaFiltersProps && <AlphaTabFilters {...alphaFiltersProps} />}
        {paginationProps && <PaginationComponent {...paginationProps} justifyContent="right" />}
        <PublicationsTable showAuthor={showAuthor} publications={publications} />
        {!publications.length && (
          <Box p={3}>
            <Typography>No Publications</Typography>
          </Box>
        )}
        {paginationProps && <PaginationComponent {...paginationProps} mt={2} />}
      </CardContent>
    </Card>
  );
};

const PublicationsWithData = ({ authorId }) => {
  const { loading, error, data } = useQuery(PUBLICATIONS_QUERY, {
    variables: { authorId },
  });

  if (loading)
    return (
      <>
        <Typography>This might take a bit, hang tight...</Typography>
        <LinearProgress data-testid="loading" />
      </>
    );
  if (error) return <Alert severity="error">{JSON.stringify(error)}</Alert>;

  return <Publications authorId={authorId} publications={data.publications} />;
};

export default PublicationsWithData;
