import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { matchSorter } from 'match-sorter';

import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { ADD_SEED_MUTATION, SEEDS_QUERY, VENDOR_SEED_OPTIONS_QUERY } from '../../queries';

const makeFilterOptions = (keys) => (options, { inputValue }) =>
  matchSorter(options, inputValue, { keys });

const useStyles = makeStyles((theme) => ({
  footerCell: {
    paddingTop: theme.spacing(2),
    borderBottom: '0',
  },
}));

const ToggleCollectAll = ({ onChange, value }) => (
  <FormControl variant="outlined">
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography variant="caption">New Docs</Typography>
      <Switch onChange={onChange} value={value} checked={value} />
      <Typography variant="caption">All Docs</Typography>
    </Box>
  </FormControl>
);

const SelectASection = ({ onChange, options, selectedOption }) => (
  <Autocomplete
    filterOptions={makeFilterOptions(['section_label'])}
    fullWidth
    getOptionLabel={(o) => o.section_label}
    onChange={onChange}
    options={options}
    renderInput={(params) => <TextField {...params} label="Select a Section" variant="outlined" />}
    required
    size="small"
    value={selectedOption}
  />
);

const SelectAnIndex = ({ disabled, onChange, options, selectedOption }) => {
  const [activeUrl, setActiveUrl] = useState(null);

  const onAutocompleteChange = (e, option) => {
    onChange(e, option);
    setActiveUrl(option?.index_url);
  };

  return (
    <Box display="flex" alignItems="center">
      <Autocomplete
        disabled={disabled}
        fullWidth
        getOptionLabel={(option) => option.index_url}
        groupBy={(option) => option.index_doctype}
        onChange={onAutocompleteChange}
        options={options}
        renderInput={(params) => (
          <TextField {...params} label="Select a Doc Type &amp; Index" variant="outlined" />
        )}
        required
        size="small"
        value={selectedOption}
      />
      {activeUrl && (
        <Link
          title="Open this index in a new tab"
          href={activeUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton size="small">
            <OpenInNewIcon />
          </IconButton>
        </Link>
      )}
    </Box>
  );
};

const HUB_DEFAULT_CONFIG = {
  collect_all: false,
  section_id: null,
  section_title: null,
  index_url: null,
  index_doctype: null,
};

export const CreateSeedErrors = ({ sectionsError, mutationError, createSeedErrors }) => {
  let message = null;

  if (sectionsError) {
    message =
      'We are encountering a network issues with the vendor. Please try again later. If the problem persists, please contact the Knowledge Platform team for assistance.';
  } else if (mutationError || createSeedErrors?.length > 0) {
    message =
      'We are encountering an internal error. Please try again later. If the problem persists, please contact the Knowledge Platform team for assistance.';
  }

  if (message) {
    return (
      <TableRow>
        <TableCell colSpan={5}>
          <Alert severity="error" children={message} />
        </TableCell>
      </TableRow>
    );
  } else {
    return null;
  }
};

export function CreateSeed({
  createSeedErrors,
  createSeedMutation,
  indexOptions,
  indexesLoading,
  mutationError,
  publicationId,
  sectionOptions,
  sectionsError,
  setChoicesFilter,
  vendorId,
}) {
  const classes = useStyles();

  const [config, setConfig] = useState(HUB_DEFAULT_CONFIG);
  const [collectAll, setCollectAll] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    setConfig((current) => ({
      ...current,
      section_id: selectedSection?.section_id,
      section_title: selectedSection?.section_title,
      index_url: selectedIndex?.index_url,
      index_doctype: selectedIndex?.index_doctype,
      collect_all: collectAll,
    }));
  }, [selectedSection, selectedIndex, collectAll, setChoicesFilter]);

  const onSwitchChange = () => setCollectAll((current) => !current);
  const onSectionChange = (_e, option) => {
    setSelectedSection(option);
    setChoicesFilter(option?.section_id);
  };
  const onIndexChange = (_e, option) => setSelectedIndex(option);
  const resetConfig = () => {
    setConfig(HUB_DEFAULT_CONFIG);
    setSelectedSection(null);
    setSelectedIndex(null);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    createSeedMutation({ variables: { publicationId, config, vendorId } });
    resetConfig();
  };

  const disableSubmit = !selectedSection || !selectedIndex;
  const disableIndexSelect = !indexOptions || !selectedSection || indexesLoading;

  return (
    <>
      <TableRow>
        <TableCell className={classes.footerCell}>
          <SelectASection
            options={sectionOptions}
            onChange={onSectionChange}
            selectedOption={selectedSection}
          />
        </TableCell>
        <TableCell colSpan="2" className={classes.footerCell}>
          <SelectAnIndex
            options={indexOptions}
            disabled={disableIndexSelect}
            onChange={onIndexChange}
            selectedOption={selectedIndex}
          />
        </TableCell>
        <TableCell align="center" className={classes.footerCell}>
          <ToggleCollectAll onChange={onSwitchChange} value={config.collect_all} />
        </TableCell>
        <TableCell align="right" className={classes.footerCell}>
          <IconButton
            type="submit"
            variant="contained"
            color="primary"
            data-testid="add-seed-submit"
            disabled={disableSubmit}
            onClick={handleSubmit}
          >
            <AddIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <CreateSeedErrors
        sectionsError={sectionsError}
        mutationError={mutationError}
        createSeedErrors={createSeedErrors}
      />
    </>
  );
}

CreateSeed.propTypes = {
  sectionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      section_id: PropTypes.string,
    })
  ),
  indexOptions: PropTypes.arrayOf(
    PropTypes.shape({
      index_doctype: PropTypes.string,
      index_id: PropTypes.string,
    })
  ),
  vendorId: PropTypes.string.isRequired,
  publicationId: PropTypes.string.isRequired,
  setChoicesFilter: PropTypes.func,
};

const CreateSeedWithData = ({ publicationId, vendorId }) => {
  const [choicesFilter, setChoicesFilter] = useState(null);

  const { loading, error: sectionsError, data: sectionsData } = useQuery(
    VENDOR_SEED_OPTIONS_QUERY,
    { variables: { vendorName: 'the_hub_ai' } }
  );

  const [fetchIndexOptions, { data: indexData, loading: indexesLoading }] = useLazyQuery(
    VENDOR_SEED_OPTIONS_QUERY,
    {
      variables: { choicesFilter, vendorName: 'the_hub_ai' },
    }
  );

  const refetchQueries = () => [{ query: SEEDS_QUERY, variables: { publicationId } }];

  const [createSeedMutation, { data: createSeedData, error: mutationError }] = useMutation(
    ADD_SEED_MUTATION,
    {
      variables: { publicationId, vendorId },
      refetchQueries,
    }
  );

  const createSeedErrors = createSeedData?.createSeed?.errors;

  useEffect(() => void fetchIndexOptions(), [choicesFilter, fetchIndexOptions]);

  if (loading)
    return (
      <TableRow>
        <TableCell colSpan={5}>
          <LinearProgress />
        </TableCell>
      </TableRow>
    );

  return (
    <CreateSeed
      vendorId={vendorId}
      publicationId={publicationId}
      sectionOptions={formatSectionOptions(sectionsData?.vendor?.seedChoices?.sections)}
      indexOptions={formatIndexOptions(indexData?.vendor?.seedChoices?.indexes)}
      indexesLoading={indexesLoading}
      setChoicesFilter={setChoicesFilter}
      createSeedMutation={createSeedMutation}
      createSeedErrors={createSeedErrors}
      sectionsError={sectionsError}
      mutationError={mutationError}
    />
  );
};

CreateSeed.propTypes = {
  publicationId: PropTypes.string,
  vendorId: PropTypes.string,
  refetchQueries: PropTypes.func,
  seed: PropTypes.shape({
    id: PropTypes.string,
    config: PropTypes.object,
  }),
};

export const formatSectionOptions = (sections = []) =>
  sections
    .map((s) => ({
      section_id: s.section_id,
      section_jurisdiction: s.jurisdiction,
      section_country: s.country,
      section_title: s.title.trim(),
      section_label: `${s.country} - ${s.jurisdiction} - ${s.title}`,
    }))
    .sort((a, b) =>
      a.section_label === b.section_label ? 0 : a.section_label > b.section_label ? 1 : -1
    );

export const formatIndexOptions = (indexes = []) =>
  indexes
    .map((i) => ({
      sortKey: `${i.doctype} ${i.url}`,
      index_url: i.url,
      index_doctype: i.doctype,
    }))
    .sort((a, b) => (a.sortKey === b.sortKey ? 0 : a.sortKey > b.sortKey ? 1 : -1));

export default CreateSeedWithData;
