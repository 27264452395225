import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Authors from '../Authors';

export default function AuthorsPage() {
  return (
    <Container maxWidth="lg">
      <Typography variant="h3" component="h1" children="Authors" />
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Dashboard</Link>
        <Typography color="textPrimary">Authors</Typography>
      </Breadcrumbs>
      <Authors />
    </Container>
  );
}
