import { gql } from '@apollo/client';

export const PUBLICATION_QUERY = gql`
  query Publication($id: ID!) {
    publication(id: $id) {
      author {
        id
        name
      }
      vendor {
        id
        name
      }
      externalId
      id
      name
      passing
      status
    }
  }
`;

export const PUBLICATIONS_QUERY = gql`
  query Publications($authorId: ID) {
    publications(authorId: $authorId, orderBy: "name") {
      author {
        id
        name
        status
      }
      collecting
      statistics {
        documentsCount
        documentsDeployedCount
        lastCollectedDate
        lastDeployedDate
      }
      id
      name
      vendor {
        name
        prettyName
      }
      passing
      status
    }
  }
`;

export const ACTIVE_PUBLICATIONS_QUERY = gql`
  query ActivePublications($first: Int, $after: String, $prefix: String) {
    activePublications(orderBy: "name", first: $first, after: $after, prefix: $prefix) {
      nodes {
        author {
          id
          name
          status
        }
        collecting
        statistics {
          documentsCount
          documentsDeployedCount
          lastCollectedDate
          lastDeployedDate
        }
        id
        name
        vendor {
          name
          prettyName
        }
        passing
        status
      }
      pageCount
      pageSize
      pageInfo {
        endCursor
        startCursor
      }
    }
  }
`;

export const ADD_PUBLICATION_MUTATION = gql`
  mutation CreatePublication(
    $authorId: ID!
    $vendorId: ID!
    $externalId: String!
    $force: Boolean
    $name: String!
  ) {
    createPublication(
      input: {
        authorId: $authorId
        vendorId: $vendorId
        externalId: $externalId
        force: $force
        name: $name
      }
    ) {
      publication {
        authorId
        vendorId
        externalId
        id
        name
        passing
        status
      }
      errors
    }
  }
`;

export const UPDATE_PUBLICATION_MUTATION = gql`
  mutation UpdatePublication(
    $externalId: String
    $id: ID!
    $force: Boolean
    $name: String
    $status: PublicationStatus
  ) {
    updatePublication(
      input: { externalId: $externalId, force: $force, id: $id, name: $name, status: $status }
    ) {
      publication {
        externalId
        id
        name
        passing
        status
      }
      errors
    }
  }
`;

export const ACTIVE_VENDORS_QUERY = gql`
  query Vendors {
    vendors(active: true) {
      id
      name
      prettyName
    }
  }
`;

export const DOCUMENTS_QUERY = gql`
  query Documents($publicationId: ID!, $limit: Int, $orderBy: String, $first: Int, $after: String) {
    documents(
      publicationId: $publicationId
      limit: $limit
      orderBy: $orderBy
      first: $first
      after: $after
    ) {
      nodes {
        publicationId
        id
        status
        createdAt
        originalUrl
      }
      pageCount
      pageSize
      pageInfo {
        endCursor
        startCursor
      }
    }
  }
`;

export const SET_PUBLICATION_ARCHIVE_STATUS = gql`
  mutation SetPublicationArchiveStatus($id: ID!, $archive: Boolean!) {
    setPublicationArchiveStatus(input: { id: $id, archive: $archive }) {
      errors
    }
  }
`;

export const TEST_CASES_QUERY = gql`
  query TestCases($publicationId: ID!, $limit: Int, $orderBy: String) {
    testCases(publicationId: $publicationId, limit: $limit, orderBy: $orderBy) {
      publicationId
      id
      rawCaseUrl
      verdict
      status
    }
  }
`;

export const ARCHIVE_TEST_CASE_MUTATION = gql`
  mutation ArchiveTestCase($id: ID!) {
    archiveTestCase(input: { id: $id }) {
      errors
    }
  }
`;

export const ADD_TEST_CASE_MUTATION = gql`
  mutation CreateTestCase($publicationId: ID!, $url: String!) {
    createTestCase(input: { publicationId: $publicationId, url: $url }) {
      testCase {
        id
        publicationId
        rawCaseUrl
      }
      errors
    }
  }
`;
