import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  alphaTab: {
    minWidth: '44px',
  },
};

const AlphaTabFilters = ({ classes, onChange, currentTab }) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let tabs = [{ value: 'ALL', label: 'ALL' }];

  tabs = [...tabs, ...letters.split('').map((l) => ({ value: l, label: l }))];

  return (
    <Tabs value={currentTab || 'ALL'} onChange={onChange} variant="scrollable" scrollButtons="auto">
      {tabs.map((tab) => (
        <Tab
          data-testid={`tab-${tab.value}`}
          key={tab.value}
          value={tab.value}
          label={tab.label}
          className={classes.alphaTab}
        />
      ))}
    </Tabs>
  );
};

export default withStyles(styles)(AlphaTabFilters);
