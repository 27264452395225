import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  dateCell: {
    whiteSpace: 'nowrap',
  },
});

export default function AuthorsTable({ authors }) {
  const classes = useStyles();
  const nullValue = '--';

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Last Collected</TableCell>
            <TableCell align="right">Last Deployed</TableCell>
            <TableCell align="right">Deployed Docs</TableCell>
            <TableCell align="right">Total Docs</TableCell>
            <TableCell align="right">Deployed Pubs</TableCell>
            <TableCell align="right">Total Pubs</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {authors.map((author) => {
            const statistics = author.statistics || {};

            return (
              <TableRow key={author.id}>
                <TableCell>
                  <Link to={`/authors/${author.id}`}>{author.name}</Link>
                </TableCell>
                <TableCell className={classes.dateCell} align="right">
                  {statistics.lastCollectedDate || nullValue}
                </TableCell>
                <TableCell className={classes.dateCell} align="right">
                  {statistics.lastDeployedDate || nullValue}
                </TableCell>
                <TableCell align="right">
                  {statistics.documentsDeployedCount || nullValue}
                </TableCell>
                <TableCell align="right">{statistics.documentsCount || nullValue}</TableCell>
                <TableCell align="right">
                  {statistics.publicationsDeployedCount || nullValue}
                </TableCell>
                <TableCell align="right">{statistics.publicationsCount || nullValue}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
