import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { useMutation } from '@apollo/client';

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import useForm from '../../../hooks/useForm';
import { UPDATE_AUTHOR_MUTATION, AUTHORS_QUERY } from '../queries';
import { ACTIVE_PUBLICATIONS_QUERY } from '../../Publications/queries';

const WARNING_REGEX = /Record not created since (.*) exists/i;

const STATUS = [
  { value: 'ENABLED', label: 'Enabled' },
  { value: 'DISABLED', label: 'Disabled' },
];

const UpdateAuthor = ({ author }) => {
  const formDefaults = {
    name: author.name,
    externalId: author.externalId,
    status: author.status,
  };

  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [allowOverride, setAllowOverride] = useState(false);
  const {
    props,
    values,
    hasChanges,
    setHasChanges,
    errors,
    warnings,
    setErrors,
    setWarnings,
  } = useForm(formDefaults);
  const refetchQueries = () => [{ query: AUTHORS_QUERY }, { query: ACTIVE_PUBLICATIONS_QUERY }];
  const [updateAuthor] = useMutation(UPDATE_AUTHOR_MUTATION, { refetchQueries });

  const handleErrors = (errors) => {
    const [warnings, formErrors] = errors.reduce(
      ([warn, error], current) =>
        current.match(WARNING_REGEX) ? [[...warn, current], error] : [warn, [...error, current]],
      [[], []]
    );

    setAllowOverride(warnings.length > 0);
    setWarnings(warnings);
    setErrors(formErrors);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const { error } = await updateAuthor({
      variables: {
        name: values.name,
        externalId: values.externalId,
        status: values.status,
        id: author.id,
        force: allowOverride,
      },
    });

    if (error) return handleErrors(error);

    setHasChanges(false);
    setIsSuccess(true);
    setLoading(false);
  };

  const isSaved = isSuccess && !hasChanges;
  const hasErrors = !!(errors && errors.length);
  const hasWarnings = !!(warnings && warnings.length);
  const submitText = isSaved ? 'Saved' : hasChanges ? 'Save Changes' : 'No Changes to Save';

  return (
    <Box p={2} component={Paper}>
      <form component="form" data-testid="update-author-form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={'Author Name'}
              margin="normal"
              variant="outlined"
              value={values.name}
              {...props.name}
            />
            <TextField
              fullWidth
              label={'External ID'}
              margin="normal"
              variant="outlined"
              value={values.externalId}
              {...props.externalId}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="demo-simple-select"
              label="Author Status"
              margin="normal"
              variant="outlined"
              select
              value={values.status}
              {...props.status}
            >
              {STATUS.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          {hasErrors && (
            <Grid item xs={12}>
              {errors.map((e, i) => (
                <Alert severity="error" key={i} children={e} />
              ))}
            </Grid>
          )}
          {hasWarnings && (
            <Grid item xs={12}>
              {warnings.map((w, i) => (
                <Alert severity="warning" key={i} children={w} />
              ))}
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              data-testid="update-author-btn"
              type="submit"
              color="primary"
              disabled={isLoading || isSaved || !hasChanges}
            >
              {submitText}
              {isSaved && (
                <Box
                  ml={1}
                  data-testid="success-message"
                  color="success.main"
                  component={CheckIcon}
                />
              )}
              {isLoading && (
                <Box ml={1} size={14} data-testid="loading" component={CircularProgress} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default UpdateAuthor;
