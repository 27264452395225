import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';

import { SEEDS_QUERY, VENDOR_QUERY } from './queries';
import { default as ScrapingHub } from './ScrapingHubSeeds';
import { default as TheHub } from './TheHubSeeds';

export default function SeedsWithData({
  vendorName,
  component: Component,
  publicationId,
  ...restProps
}) {
  const {
    loading: publicationLoading,
    error: publicationError,
    data: publicationData,
  } = useQuery(SEEDS_QUERY, { variables: { publicationId } });

  const { loading: vendorLoading, error: vendorError, data: vendorData } = useQuery(VENDOR_QUERY, {
    variables: { name: vendorName },
  });

  if (!publicationId || publicationError || vendorError)
    return <Alert children="Something went wrong :(" />;

  if (publicationLoading || vendorLoading) return <LinearProgress data-testid="loading" />;

  return (
    <Component
      publicationId={publicationId}
      seeds={publicationData.seeds}
      vendorId={vendorData.vendor.id}
      {...restProps}
    />
  );
}

SeedsWithData.propTypes = {
  component: PropTypes.elementType,
  publicationId: PropTypes.string,
  vendorName: PropTypes.string,
};

export const ScrapingHubSeeds = (props) => (
  <SeedsWithData vendorName="scrapinghub" component={ScrapingHub} {...props} />
);

export const TheHubSeeds = (props) => (
  <SeedsWithData vendorName="the_hub_ai" component={TheHub} {...props} />
);
