import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Seed from './Seed';
import CreateSeed from './CreateSeed';

const useStyles = makeStyles({
  sectionColumn: {
    width: '30%',
  },
  doctypeColumn: {
    width: '8%',
  },
  indexColumn: {
    width: '40%',
  },
});

export default function TheHubSeeds({ publicationId, vendorId, seeds, ...rest }) {
  const classes = useStyles();

  if (!seeds) return 'No seeds to show.';

  return (
    <Card {...rest}>
      <CardHeader
        title="TheHub Seeds"
        subheader="Seeds define how and where a Publication gathers documents.
        Once created, a Seed cannot be modified."
      />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.sectionColumn}>Section</TableCell>
              <TableCell className={classes.doctypeColumn}>Doc Type</TableCell>
              <TableCell className={classes.indexColumn}>Index</TableCell>
              <TableCell align="center">Collecting</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {seeds.map((seed) => (
              <Seed seed={seed} key={seed.id} publicationId={publicationId} />
            ))}
          </TableBody>
          <TableFooter>
            <CreateSeed publicationId={publicationId} vendorId={vendorId} />
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  );
}

TheHubSeeds.propTypes = {
  seeds: PropTypes.arrayOf(
    PropTypes.shape({
      config: PropTypes.shape({
        collect_all: PropTypes.bool.isRequired,
        index_url: PropTypes.string.isRequired,
        section_title: PropTypes.string,
        section_id: PropTypes.string.isRequired,
      }),
    })
  ),
  publicationId: PropTypes.string.isRequired,
  vendorId: PropTypes.string,
};
