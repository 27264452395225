import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { AuthorPage, AuthorsPage } from '../../Authors';
import { PublicationPage, PublicationsPage } from '../../Publications';
import Login from '../../Login';
import NotFound from '../../NotFound';

export default function Main() {
  return (
    <Box component="main" py={4}>
      <Switch>
        <Route exact path="/">
          <Redirect to={{ pathname: '/authors' }} />
        </Route>
        <Route path="/authors" exact component={AuthorsPage} />
        <Route path="/authors/:authorId" component={AuthorPage} />
        <Route path="/publications" exact component={PublicationsPage} />
        <Route path="/publications/:publicationId" component={PublicationPage} />
        <Route path="/login" component={Login} />
        <Route component={NotFound} />
      </Switch>
    </Box>
  );
}
