import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

export default createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          padding: defaultTheme.spacing(9, 0, 0, 9),
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        marginBottom: defaultTheme.spacing(2),
      },
    },
    MuiTabs: {
      root: {
        marginBottom: defaultTheme.spacing(2),
      },
      flexContainer: {
        borderBottom: '1px solid lightgrey',
      },
    },
    MuiTypography: {
      h1: { marginBottom: defaultTheme.spacing(2) },
      h2: { marginBottom: defaultTheme.spacing(2) },
      h3: { marginBottom: defaultTheme.spacing(2) },
    },
  },
});
