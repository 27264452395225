import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Seed from './Seed';
import CreateSeed from './CreateSeed';
import ConfirmDialog from '../../ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  modeToggle: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function ScrapingHubSeeds({ publicationId, vendorId, seeds, ...restProps }) {
  const [isAdvanced, setAdvancedMode] = useState(false);
  const [revertIsConfirmd, setRevertConfirmed] = useState(false);
  const [confirmIsOpen, setConfirmOpen] = useState(false);

  const classes = useStyles();

  function toggleAdvancedMode() {
    if (!isAdvanced) return setAdvancedMode(true);
    setConfirmOpen(true);
  }

  function handleConfirm() {
    setAdvancedMode(false);
    setRevertConfirmed(true);
    setConfirmOpen(false);
  }

  function handleCancel() {
    setConfirmOpen(false);
  }

  return (
    <Card {...restProps}>
      <CardHeader
        action={
          <FormControlLabel
            className={classes.modeToggle}
            label={`Advanced Mode is ${!!isAdvanced ? 'ON' : 'OFF'}`}
            labelPlacement="start"
            control={<Switch size="small" checked={isAdvanced} onChange={toggleAdvancedMode} />}
          ></FormControlLabel>
        }
        title="ScrapingHub Seeds"
        subheader="Seeds define how and where a Publication gathers documents. Once created, a Seed cannot be
          modified."
      />

      <ConfirmDialog
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        cancelText="Nevermind"
        confirmText="Yes, I'm Sure"
        isOpen={confirmIsOpen}
        title="Are you sure?"
        description="If you disable Advanced Mode, you will lose any unsaved changes."
      />

      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {isAdvanced ? (
                  <>
                    <TableCell colSpan={3}>Seed Configuration</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>URL Pattern</TableCell>
                    <TableCell align="center">Collect HTML</TableCell>
                    <TableCell align="center">Crawl Depth</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {seeds.map((seed) => (
                <Seed
                  key={seed.id}
                  isAdvanced={isAdvanced}
                  seed={seed}
                  publicationId={publicationId}
                />
              ))}
            </TableBody>
            <TableFooter>
              <CreateSeed
                isAdvanced={isAdvanced}
                revertIsConfirmed={revertIsConfirmd}
                publicationId={publicationId}
                vendorId={vendorId}
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

ScrapingHubSeeds.propTypes = {
  publicationId: PropTypes.string,
  seeds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      config: PropTypes.shape({
        pattern: PropTypes.string,
        depth: PropTypes.number,
        html: PropTypes.boolean,
        exclude_css: PropTypes.boolean,
        limit_css: PropTypes.boolean,
      }),
    })
  ).isRequired,
  vendorId: PropTypes.string,
};
