import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Chip from '@material-ui/core/Chip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DOCUMENTS_QUERY } from '../queries';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import withTitleCase from '../../../hooks/withTitleCase';
import Pagination from '@material-ui/lab/Pagination';
import { pageToCursor, cursorToPage, useLocationSearch } from '../../../utils/searchParams';
import { compactObject } from '../../../utils/object';

const SHADE = 300;
const useStyles = makeStyles((theme) => ({
  link: {
    display: 'inline-block',
  },
  dateCell: {
    whiteSpace: 'nowrap',
  },
  green: {
    backgroundColor: green[SHADE],
  },
  orange: {
    backgroundColor: orange[SHADE],
  },
  linkCell: {
    lineBreak: 'anywhere',
  },
}));

export function PublicationDocuments({ documents, page, pageCount, setPage }) {
  const classes = useStyles();

  const onPageClick = (_event, page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  if (!documents || documents.length === 0) return 'No documents to show.';

  return (
    <Box p={2} component={Paper}>
      <Box display="flex" justifyContent="flex-end">
        <Pagination
          data-testid="pagination-controls"
          count={pageCount}
          onChange={onPageClick}
          page={page}
          showFirstButton
          showLastButton
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Original URL</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((d) => {
            const { createdAt, originalUrl, status, id } = d;

            return (
              <TableRow key={`row-${id}`}>
                <TableCell
                  className={classes.linkCell}
                  data-testid={`url-cell-${id}`}
                  key={`url-cell-${id}`}
                >
                  <a className={classes.link} href={originalUrl}>
                    {originalUrl}
                  </a>
                </TableCell>
                <TableCell
                  className={classes.dateCell}
                  data-testid={`date-cell-${id}`}
                  key={`date-cell-${id}`}
                >
                  {createdAt.split('T')[0]}
                </TableCell>
                <TableCell data-testid={`status-cell-${id}`} key={`status-cell-${id}`}>
                  <Chip
                    size="small"
                    label={withTitleCase(status)}
                    className={status === 'DEPLOYED' ? classes.green : classes.orange}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box display="flex" justifyContent="center">
        <Pagination
          data-testid="pagination-controls"
          count={pageCount}
          onChange={onPageClick}
          page={page}
          showFirstButton
          showLastButton
        />
      </Box>
    </Box>
  );
}

PublicationDocuments.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      originalUrl: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

export default function PublicationDocumentsWithData({ publicationId }) {
  const {
    params: { after },
    replaceSearchParams,
  } = useLocationSearch();

  const { loading, error, data } = useQuery(DOCUMENTS_QUERY, {
    variables: { publicationId, orderBy: 'created_at DESC', after: after },
  });

  if (loading) return <LinearProgress data-testid="loading" />;
  if (error) return <Alert severity="error" children={'Something went wrong :('} />;

  const { nodes: documents, pageSize, pageCount } = data.documents;

  const page = cursorToPage(after, pageSize);

  const setPage = (newPage) => {
    const newAfter = pageToCursor(newPage, pageSize);

    replaceSearchParams(compactObject({ after: newAfter }));
  };

  return (
    <PublicationDocuments
      documents={documents}
      page={page}
      pageCount={pageCount}
      setPage={setPage}
    />
  );
}

PublicationDocumentsWithData.propTypes = {
  publicationId: PropTypes.string.isRequired,
};
