import React from 'react';
import { Switch, Route, useLocation, useRouteMatch, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import { ScrapingHubSeeds, TheHubSeeds } from '../../Seeds';
import PublicationDetails from '../PublicationDetails';
import PublicationDocuments from '../PublicationDocuments';
import PublicationMore from '../PublicationMore';
import PublicationTestCases from '../PublicationTestCases';
import { PUBLICATION_QUERY } from '../queries';

const useStyles = makeStyles((theme) => ({
  spaceBelow: {
    marginBottom: theme.spacing(3),
  },
  topBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const PublicationSeeds = ({ vendorName, publicationId, ...rest }) => {
  return vendorName === 'the_hub_ai' ? (
    <TheHubSeeds publicationId={publicationId} {...rest} />
  ) : (
    <>
      <ScrapingHubSeeds publicationId={publicationId} {...rest} />
      <PublicationTestCases publicationId={publicationId} {...rest} />
    </>
  );
};

export function PublicationPage({ publication }) {
  const {
    id: publicationId,
    name: publicationName,
    author: { id: authorId, name: authorName },
    vendor,
  } = publication;

  const location = useLocation();
  const match = useRouteMatch();
  const classes = useStyles();

  const indexUrl = match.url;
  const indexPath = match.path;
  const isIndex = location.pathname === indexUrl;
  const docsUrl = `${match.url}/documents`;
  const docsPath = `${match.path}/documents`;

  const authorUrl = `/authors/${authorId}`;
  const authorPublicationsUrl = `${authorUrl}/publications`;

  return (
    <Container maxWidth="lg">
      <Box className={classes.topBox}>
        <Typography variant="h3" component="h1" children={publicationName} />

        <PublicationMore
          authorId={authorId}
          publicationId={publicationId}
          publicationName={publicationName}
        />
      </Box>

      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Dashboard</Link>
        <Link to="/authors">Authors</Link>
        <Link to={authorUrl}>{authorName}</Link>
        <Link to={authorPublicationsUrl}>Publications</Link>
        {isIndex && <Typography color="textPrimary">{publicationName}</Typography>}
        {!isIndex && <Link to={indexUrl}>{publicationName}</Link>}
        {!isIndex && <Typography color="textPrimary">Documents</Typography>}
      </Breadcrumbs>

      <Tabs aria-label="Publication tabs" value={location.pathname}>
        <Tab value={indexUrl} label="Details" component={Link} to={indexUrl} />
        <Tab value={docsUrl} label="Documents" component={Link} to={docsUrl} />
      </Tabs>

      <Switch>
        <Route path={indexPath} exact>
          <PublicationDetails publicationId={publicationId} className={classes.spaceBelow} />
          <PublicationSeeds
            vendorName={vendor.name}
            publicationId={publicationId}
            className={classes.spaceBelow}
          />
        </Route>
        <Route path={docsPath}>
          <PublicationDocuments publicationId={publicationId} />
        </Route>
      </Switch>
    </Container>
  );
}

const PublicationPageWithData = () => {
  const { publicationId } = useParams();

  const { loading, error, data } = useQuery(PUBLICATION_QUERY, {
    variables: { id: publicationId },
  });

  if (loading) return <LinearProgress data-testid="loading" />;
  if (error) return <Alert severity="error" children="Something went wrong :(" />;

  return <PublicationPage publication={data.publication} />;
};

export default PublicationPageWithData;
