import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/AddOutlined';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

import { ADD_SEED_MUTATION, SEEDS_QUERY } from '../../queries';

const useStyles = makeStyles((theme) => ({
  footerCell: {
    paddingTop: theme.spacing(2),
    borderBottom: '0',
  },
}));

const DEFAULT_CONFIG = {
  pattern: '',
  depth: 0,
  html: false,
  exclude_css: '',
  limit_css: '',
};

export function CreateSeed({
  isAdvanced = false,
  publicationId,
  vendorId,
  revertIsConfirmed = false,
}) {
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([]);

  const DEFAULT_FORM = {
    pattern: DEFAULT_CONFIG.pattern,
    json: JSON.stringify(DEFAULT_CONFIG),
  };
  const [formValues, setFormValues] = useState(DEFAULT_FORM);

  const reset = useCallback(() => {
    setErrors([]);
    setFormValues(DEFAULT_FORM);
    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    e.persist(); // make material event non-synthetic
    setFormValues((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const refetchQueries = () => [{ query: SEEDS_QUERY, variables: { publicationId } }];

  const [createSeed] = useMutation(ADD_SEED_MUTATION, { refetchQueries });

  const doSubmit = async (e) => {
    const config = {
      ...DEFAULT_CONFIG,
      ...(isAdvanced ? JSON.parse(formValues.json) : { pattern: formValues.pattern }),
    };

    e.preventDefault();
    const {
      data: {
        createSeed: { errors },
      },
    } = await createSeed({
      variables: {
        publicationId,
        vendorId,
        config,
      },
    });

    setErrors(errors);
    if (!errors.length) reset();
  };

  useEffect(
    function () {
      function advancedIsValid() {
        try {
          const json = JSON.parse(formValues.json);

          setErrors([]);

          return json.pattern.trim().length > 1;
        } catch (err) {
          setErrors(['Invalid JSON']);

          return false;
        }
      }
      function basicIsValid() {
        return formValues.pattern.trim().length > 1;
      }
      const validity = isAdvanced ? advancedIsValid() : basicIsValid();

      setIsValid(validity);
    },
    [formValues, setErrors, isAdvanced]
  );

  useEffect(() => {
    if (isAdvanced)
      return setFormValues((oldValues) => ({
        ...oldValues,
        json: JSON.stringify({ ...DEFAULT_CONFIG, pattern: formValues.pattern }),
      }));

    revertIsConfirmed && reset();
    // eslint-disable-next-line
  }, [isAdvanced, revertIsConfirmed]);

  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell colSpan={3} className={classes.footerCell}>
          {isAdvanced ? (
            <TextField
              required
              fullWidth
              size="small"
              variant="outlined"
              label="Seed Configuration"
              name={'json'}
              inputProps={{ 'data-testid': 'input-json' }}
              onChange={(e) => onChange(e)}
              value={formValues.json}
            />
          ) : (
            <TextField
              required
              fullWidth
              size="small"
              variant="outlined"
              label="URL pattern"
              name={'pattern'}
              inputProps={{ 'data-testid': 'input-pattern' }}
              onChange={(e) => onChange(e)}
              value={formValues.pattern}
            />
          )}
        </TableCell>
        <TableCell className={classes.footerCell} align="right">
          <IconButton type="button" color="primary" disabled={!isValid} onClick={doSubmit}>
            <AddIcon data-testid="submit-button" />
          </IconButton>
        </TableCell>
      </TableRow>
      {errors.map((e, i) => (
        <Alert key={i} children={e} severity="error" />
      ))}
    </>
  );
}

CreateSeed.propTypes = {
  isAdvanced: PropTypes.bool,
  publicationId: PropTypes.string,
  vendorId: PropTypes.string,
};

export default CreateSeed;
