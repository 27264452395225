import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import useForm from '../../../hooks/useForm';
import {
  PUBLICATION_QUERY,
  PUBLICATIONS_QUERY,
  ACTIVE_PUBLICATIONS_QUERY,
  UPDATE_PUBLICATION_MUTATION,
} from '../queries';

export function PublicationDetails({ publication, ...rest }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { props, values, errors, setErrors, hasChanges, setHasChanges } = useForm({
    name: publication.name,
    vendor: publication.vendor.name,
    externalId: publication.externalId,
    status: publication.status,
  });

  const refetchQueries = [
    { query: PUBLICATIONS_QUERY, variables: { authorId: publication.author.id } },
    { query: ACTIVE_PUBLICATIONS_QUERY },
  ];
  const [updatePublication] = useMutation(UPDATE_PUBLICATION_MUTATION, { refetchQueries });

  const handleSubmit = async (evt) => {
    setLoading(true);
    evt.preventDefault();

    setIsSuccess(false);
    setHasChanges(false);

    const {
      data: {
        updatePublication: { errors },
      },
    } = await updatePublication({
      variables: {
        id: publication.id,
        name: values.name,
        vendor: values.vendor.name,
        externalId: values.externalId,
        status: values.status,
        force: true,
      },
    });

    setErrors(errors);
    setIsSuccess(!errors.length);
    setLoading(false);
  };

  const isValid = !!(values.vendor && values.name && values.externalId);
  const isSaved = isSuccess && !hasChanges;
  const hasErrors = !!errors.length;
  const submitText = isSaved ? 'Saved' : hasChanges ? 'Save Changes' : 'No Changes to Save';

  return (
    <Box p={2} component={Paper} {...rest}>
      <form component="form" data-testid="PublicationDetails" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <TextField
              required
              fullWidth
              label="Name"
              variant="outlined"
              value={values.name}
              {...props.name}
            />
          </Grid>
          <Grid item sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="pub-status">Status</InputLabel>
              <Select
                required
                labelId="pub-status"
                label="Status"
                value={values.status}
                {...props.status}
              >
                <MenuItem value="COLLECT_ONLY">Collect Only</MenuItem>
                <MenuItem value="COLLECT_AND_DEPLOY">Collect &amp; Deploy</MenuItem>
                <MenuItem value="DISABLED">Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <TextField
              required
              fullWidth
              label="External ID"
              variant="outlined"
              value={values.externalId}
              {...props.externalId}
            />
          </Grid>

          {hasErrors && (
            <Grid item xs={12}>
              {errors.map((e, i) => (
                <Alert severity="error" key={i} children={e} />
              ))}
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              data-testid="save-button"
              type="submit"
              color="primary"
              disabled={isLoading || !isValid || !hasChanges}
            >
              {submitText}
              {isSaved && (
                <Box
                  ml={1}
                  data-testid="success-message"
                  color="success.main"
                  component={CheckIcon}
                />
              )}
              {isLoading && (
                <Box ml={1} size={14} data-testid="loading" component={CircularProgress} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

PublicationDetails.propTypes = {
  publication: PropTypes.shape({
    author: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    externalId: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default function PublicationDetailsWithData({ publicationId, ...rest }) {
  const { loading, error, data } = useQuery(PUBLICATION_QUERY, {
    variables: { id: publicationId },
  });

  if (!publicationId || error) return <Alert children="Something went wrong :(" />;
  if (loading) return <LinearProgress data-testid="loading" />;

  return <PublicationDetails publication={data.publication} {...rest} />;
}

PublicationDetailsWithData.propTypes = {
  publicationId: PropTypes.string,
};
