import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';
import TableRow from '@material-ui/core/TableRow';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import ArchiveSeed from '../../ArchiveSeed';

const useStyles = makeStyles({
  linkIcon: {
    verticalAlign: 'bottom',
    marginLeft: '0.25em',
  },
  linkCell: {
    lineBreak: 'anywhere',
  },
});

function Seed({ seed, publicationId, ...restProps }) {
  const classes = useStyles();
  const { section_title, index_doctype, index_url, collect_all } = seed.config;

  return (
    <TableRow {...restProps}>
      <TableCell>{section_title}</TableCell>
      <TableCell>{index_doctype}</TableCell>
      <TableCell className={classes.linkCell}>
        <Link href={index_url} target="_blank" rel="noopener noreferrer">
          {index_url}
          <OpenInNewIcon className={classes.linkIcon} fontSize="small" />
        </Link>
      </TableCell>
      <TableCell align="center">{collect_all ? 'All Docs' : 'New Docs'}</TableCell>
      <TableCell align="right">
        <ArchiveSeed seed={seed} publicationId={publicationId} />
      </TableCell>
    </TableRow>
  );
}

Seed.propTypes = {
  refetchQueries: PropTypes.func,
  seed: PropTypes.shape({
    config: PropTypes.shape({
      section_id: PropTypes.string,
      index_url: PropTypes.string,
      index_doctype: PropTypes.string,
      section_title: PropTypes.string,
      collect_all: PropTypes.bool,
    }),
  }),
};

export default Seed;
