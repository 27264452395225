import { gql } from '@apollo/client';

export const SEEDS_QUERY = gql`
  query Seeds($publicationId: ID!) {
    seeds(publicationId: $publicationId) {
      config
      id
      publicationId
      vendorId
    }
  }
`;

export const VENDOR_QUERY = gql`
  query Vendor($name: String) {
    vendor(name: $name) {
      id
      name
    }
  }
`;

export const VENDOR_SEED_OPTIONS_QUERY = gql`
  query VendorSeedChoices($vendorName: String, $choicesFilter: String) {
    vendor(name: $vendorName) {
      id
      seedChoices(choicesFilter: $choicesFilter)
    }
  }
`;

export const ADD_SEED_MUTATION = gql`
  mutation CreateSeed($publicationId: ID!, $vendorId: ID!, $config: JSON!) {
    createSeed(input: { publicationId: $publicationId, vendorId: $vendorId, config: $config }) {
      seed {
        config
        id
        publicationId
        vendorId
      }
      errors
    }
  }
`;

export const ARCHIVE_SEED_MUTATION = gql`
  mutation ArchiveSeed($id: ID!) {
    archiveSeed(input: { id: $id }) {
      errors
    }
  }
`;
