import { gql } from '@apollo/client';

export const AUTHOR_QUERY = gql`
  query Author($id: ID!) {
    author(id: $id) {
      id
      name
      status
      externalId
      statistics {
        authorName
        authorStatus
        documentsCount
        documentsDeployedCount
        publicationsCount
        publicationsDeployedCount
      }
    }
  }
`;

export const AUTHORS_QUERY = gql`
  query Authors($first: Int, $after: String, $prefix: String) {
    authors(orderBy: "name", prefix: $prefix, first: $first, after: $after) {
      nodes {
        id
        name
        statistics {
          documentsCount
          documentsDeployedCount
          lastCollectedDate
          lastDeployedDate
          publicationsCount
          publicationsDeployedCount
        }
      }
      pageCount
      pageSize
      pageInfo {
        endCursor
        startCursor
      }
    }
  }
`;

export const ADD_AUTHOR_MUTATION = gql`
  mutation CreateAuthor($name: String!, $externalId: String!, $force: Boolean) {
    createAuthor(input: { name: $name, externalId: $externalId, force: $force }) {
      author {
        name
        externalId
        id
      }
      errors
    }
  }
`;

export const UPDATE_AUTHOR_MUTATION = gql`
  mutation UpdateAuthor(
    $id: ID!
    $name: String!
    $externalId: String!
    $status: AuthorStatus!
    $force: Boolean
  ) {
    updateAuthor(
      input: { id: $id, name: $name, externalId: $externalId, status: $status, force: $force }
    ) {
      author {
        id
        name
        status
        externalId
      }
      errors
    }
  }
`;

export const SET_AUTHOR_ARCHIVE_STATUS = gql`
  mutation SetAuthorArchiveStatus($id: ID!, $archive: Boolean!) {
    setAuthorArchiveStatus(input: { id: $id, archive: $archive }) {
      errors
    }
  }
`;
