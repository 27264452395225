import React, { forwardRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';

import ConfirmDialog from '../../ConfirmDialog';
import { SET_PUBLICATION_ARCHIVE_STATUS, PUBLICATIONS_QUERY } from '../queries';

const useStyles = makeStyles({
  publicationName: {
    display: 'block',
    marginTop: '0.7rem',
  },
  mutationStatus: {
    marginTop: '0.8rem',
  },
  itemText: {
    fontSize: '0.9rem',
    marginLeft: '0.3rem',
  },
});

export const ArchiveDialog = ({
  open,
  authorId,
  publicationId,
  publicationName,
  handler: dialogHandler,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [mutationError, setMutationError] = useState(null);

  const refetchQueries = [{ query: PUBLICATIONS_QUERY, variables: { authorId } }];
  const [archivePublication] = useMutation(SET_PUBLICATION_ARCHIVE_STATUS, {
    variables: { id: publicationId, archive: true },
    awaitRefetchQueries: true,
    refetchQueries,
  });

  const cancelHandler = () => {
    setMutationError(null);
    dialogHandler(!open);
  };

  const archiveHandler = async () => {
    setMutationError(null);
    setLoading(true);

    try {
      const {
        data: {
          setPublicationArchiveStatus: { errors },
        },
      } = await archivePublication();

      setLoading(false);

      if (errors.length) setMutationError(errors[0]);
      else history.push(`/authors/${authorId}/publications`);
    } catch (error) {
      setMutationError('Something went wrong :(');
      setLoading(false);

      throw error;
    }
  };

  const renderDialogBody = () => (
    <div>
      <Typography className={classes.publicationName}>{publicationName}</Typography>

      {loading && <LinearProgress className={classes.mutationStatus} />}

      {mutationError && (
        <Alert severity="error" className={classes.mutationStatus} children={mutationError} />
      )}
    </div>
  );

  return (
    <ConfirmDialog
      fullWidth
      isOpen={open}
      cancelText="Cancel"
      confirmText="Archive"
      data-testid="archive-dialog"
      title="Are you sure you want to delete this publication?"
      onCancel={cancelHandler}
      onConfirm={archiveHandler}
      children={renderDialogBody()}
    />
  );
};

const ArchiveItem = forwardRef(({ open, handler }, ref) => {
  const classes = useStyles();

  return (
    <MenuItem innerRef={ref} onClick={() => handler(!open)} data-testid="archive-item">
      <ArchiveIcon />
      <span className={classes.itemText}>Archive</span>
    </MenuItem>
  );
});

export default ArchiveItem;
