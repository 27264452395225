import { ApolloClient, InMemoryCache } from '@apollo/client';

const KEY_PREFIX = window.location.host;

const promptLocalStorageItem = (key, promptValue) => {
  let scopedKey = `${KEY_PREFIX}_${key}`;
  let val = localStorage.getItem(scopedKey);

  if (val && val !== 'null') return val;

  val = prompt(promptValue);
  localStorage.setItem(scopedKey, val);

  return val;
};

const user = promptLocalStorageItem('harvest_user', 'Username?');
const pass = promptLocalStorageItem('harvest_pass', 'Password?');
const token = btoa(`${user}:${pass}`);

export default new ApolloClient({
  connectToDevTools: true,
  uri: `${window.location.protocol}//${window.location.host}/graphql`,
  headers: { Authorization: `Basic ${token}` },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          authors: { merge: (_existing, incoming) => incoming },
          publications: { merge: (_existing, incoming) => incoming },
          vendor: { merge: (_existing, incoming) => incoming },
        },
      },
    },
  }),
});
