import React from 'react';
import { useQuery } from '@apollo/client';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import AddPublication from '../../Publications/AddPublication';
import UpdateAuthor from '../UpdateAuthor';
import { AUTHOR_QUERY } from '../queries';

function DeployedAndTotal({ deployedCt, totalCt }) {
  return (
    <Box display="flex" justifyContent="space-evenly">
      <Box textAlign="center" color="green">
        <Typography variant="h4" component="p">
          {deployedCt}
        </Typography>
        <Typography component="p">deployed </Typography>
      </Box>
      <Typography variant="h4" component="p">
        /
      </Typography>
      <Box textAlign="center">
        <Typography variant="h4" component="p">
          {totalCt}
        </Typography>
        <Typography component="p">total</Typography>
      </Box>
    </Box>
  );
}

function StatisticsSummaryCards({ statistics }) {
  return (
    <>
      <Grid item xs={6}>
        <Card>
          <CardHeader avatar={<AssignmentOutlinedIcon />} title="Publications" />
          <CardContent>
            <DeployedAndTotal
              deployedCt={statistics.publicationsDeployedCount}
              totalCt={statistics.publicationsCount}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <CardHeader avatar={<InsertDriveFileOutlinedIcon />} title="Documents" />
            <DeployedAndTotal
              deployedCt={statistics.documentsDeployedCount}
              totalCt={statistics.documentsCount}
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export const AuthorDetails = ({ author }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <UpdateAuthor author={author} />
    </Grid>
    {!author.statistics || author.statistics.publicationsCount === 0 ? (
      <Grid item xs={6}>
        <AddPublication authorId={author.id} />
      </Grid>
    ) : (
      <StatisticsSummaryCards statistics={author.statistics} />
    )}
  </Grid>
);

export default function AuthorDetailsWithData({ authorId }) {
  const { loading, error, data } = useQuery(AUTHOR_QUERY, { variables: { id: authorId } });

  if (loading) return <LinearProgress data-testid="loading" />;
  if (error) return <Alert severity="error">{JSON.stringify(error)}</Alert>;

  return <AuthorDetails author={data.author} />;
}
