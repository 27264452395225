import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ArchiveItem, { ArchiveDialog } from './ArchiveItem';

export const AuthorMore = ({ authorId, authorName, ...rest }) => {
  const anchorEl = useRef(null);
  const [moreOpen, setMoreOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);

  const handler = () => setMoreOpen(!moreOpen);

  return (
    <>
      <Button
        ref={anchorEl}
        onClick={handler}
        aria-controls="simple-menu"
        data-testid="author-more-btn"
        {...rest}
      >
        <MoreVertIcon fontSize="large" />
      </Button>

      <Menu anchorEl={anchorEl.current} open={moreOpen} onClose={handler} data-testid="author-more">
        {/* List of all required menu items */}
        <ArchiveItem open={archiveDialogOpen} handler={setArchiveDialogOpen} />
      </Menu>

      {/* Additional components needed by the menu items */}
      <ArchiveDialog
        authorId={authorId}
        authorName={authorName}
        open={archiveDialogOpen}
        handler={setArchiveDialogOpen}
      />
    </>
  );
};

export default AuthorMore;
