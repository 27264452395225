import { useLocation, useHistory } from 'react-router-dom';

export const useLocationSearch = () => {
  const location = useLocation();
  const history = useHistory();

  let params = {};

  for (const [key, val] of new URLSearchParams(location.search).entries()) params[key] = val;

  const setSearchParams = (newParams) => {
    const newSearch = toSearchString({ ...params, ...newParams });

    history.push(`${location.pathname}?${newSearch}`);
  };

  const replaceSearchParams = (newParams) => {
    const newSearch = toSearchString(newParams);

    history.push(`${location.pathname}?${newSearch}`);
  };

  return { params, setSearchParams, replaceSearchParams };
};

export const toSearchString = (hash) => new URLSearchParams(hash).toString();

export const pageToCursor = (page, pageSize) =>
  page === 1 ? undefined : btoa((page - 1) * pageSize - 1);

export const cursorToPage = (cursor, pageSize) =>
  cursor ? Math.round(parseInt(atob(cursor), 10) / pageSize) + 1 : 1;
