import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import AuthorsTable from '../AuthorsTable';
import AddAuthor from '../AddAuthor';
import { AUTHORS_QUERY } from '../queries';
import Pagination from '@material-ui/lab/Pagination';
import { pageToCursor, cursorToPage, useLocationSearch } from '../../../utils/searchParams';
import { compactObject } from '../../../utils/object';
import AlphaTabFilters from '../../AlphaTabFilters';

function Authors({ authors, page, pageCount, setPage, tabsOnChange, currentTab }) {
  const onPageClick = (_event, page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  return (
    <Card>
      <CardContent>
        <AlphaTabFilters onChange={tabsOnChange} currentTab={currentTab} />
        <Box display="flex" justifyContent="space-between">
          <AddAuthor />
          <Pagination
            data-testid="pagination-controls"
            count={pageCount}
            onChange={onPageClick}
            page={page}
            showFirstButton
            showLastButton
          />
        </Box>
        <AuthorsTable authors={authors} />
        {authors.length === 0 && <Box p={3} children="No Authors" />}
        <Box display="flex" justifyContent="center">
          <Pagination
            data-testid="pagination-controls"
            count={pageCount}
            onChange={onPageClick}
            page={page}
            showFirstButton
            showLastButton
          />
        </Box>
      </CardContent>
    </Card>
  );
}

Authors.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      statistics: PropTypes.shape({
        documentsCount: PropTypes.number,
        documentsDeployedCount: PropTypes.number,
        lastCollectedDate: PropTypes.string,
        lastDeployedDate: PropTypes.string,
        publicationsCount: PropTypes.number,
        publicationsDeployedCount: PropTypes.number,
      }),
    })
  ),
};

export default function AuthorsWithData() {
  const {
    params: { after, prefix },
    replaceSearchParams,
  } = useLocationSearch();

  const { loading, error, data } = useQuery(AUTHORS_QUERY, {
    variables: { after: after, prefix: prefix },
  });

  if (loading) {
    const waitMessage = 'This might take a bit, hang tight...';

    return (
      <>
        <Typography component="p">{waitMessage}</Typography>
        <LinearProgress data-testid="loading" />
      </>
    );
  }
  if (error) return <Alert severity="error" children="Something went wrong :(" />;

  const { nodes: authors, pageSize, pageCount } = data.authors;

  const page = cursorToPage(after, pageSize);

  const setPage = (newPage) => {
    const newAfter = pageToCursor(newPage, pageSize);

    replaceSearchParams(compactObject({ after: newAfter, prefix: prefix }));
  };

  function tabsOnChange(_e, tabval) {
    replaceSearchParams(compactObject({ prefix: tabval === 'ALL' ? undefined : tabval }));
  }

  return (
    <Authors
      authors={authors}
      page={page}
      pageCount={pageCount}
      setPage={setPage}
      tabsOnChange={tabsOnChange}
      currentTab={prefix}
    />
  );
}
