import React, { useState } from 'react';
import { HashRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Header, Sidebar, Main } from '../Layout';
import harvestApiClient from '../../apis/harvest';
import theme from './theme';

export default function App() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <ApolloProvider client={harvestApiClient}>
      <HashRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen} />
          <Sidebar isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen} />
          <Main />
        </ThemeProvider>
      </HashRouter>
    </ApolloProvider>
  );
}
