import React, { useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddOutlined';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';

import useForm from '../../../hooks/useForm';
import withTitleCase from '../../../hooks/withTitleCase';
import ConfirmDialog from '../../ConfirmDialog';
import { TEST_CASES_QUERY, ARCHIVE_TEST_CASE_MUTATION, ADD_TEST_CASE_MUTATION } from '../queries';
import { Typography, TableBody } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    textAlign: 'center',
  },
  passing: {
    backgroundColor: green[400],
  },
  pending: {
    backgroundColor: orange[400],
  },
  footerCell: {
    paddingTop: theme.spacing(2),
    borderBottom: '0',
  },
}));

function AddTestCaseRow({ inputProps, formValues }) {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell colSpan={2} className={classes.footerCell}>
        <TextField
          required
          size="small"
          fullWidth
          label="URL"
          variant="outlined"
          value={formValues.url}
          {...inputProps.url}
        />
      </TableCell>
      <TableCell align="center" className={classes.footerCell}>
        <IconButton
          data-testid="submit-button"
          type="submit"
          disabled={formValues.url.length === 0}
          color="primary"
        >
          <AddIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function VerdictChip({ verdict }) {
  const classes = useStyles();
  const label = withTitleCase(verdict);

  // apply whatever color is set for that verdict
  return <Chip className={classes[verdict]} label={label} />;
}

function TestCaseRow({ refetchQueries, testCase }) {
  const classes = useStyles();

  const [archiveTestCase] = useMutation(ARCHIVE_TEST_CASE_MUTATION, { refetchQueries });

  const [errors, setErrors] = useState([]);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);

  const onArchiveClick = () => {
    setArchiveDialogOpen(true);
  };

  const onArchiveConfirm = useCallback(async () => {
    const { error } = await archiveTestCase({ variables: { id: testCase.id } });

    if (error) return setErrors([error.graphQLErrors.message]);
    setArchiveDialogOpen(false);
  }, [archiveTestCase, testCase]);

  const onArchiveCancel = () => setArchiveDialogOpen(false);

  return (
    <TableRow key={testCase.id}>
      <TableCell className={classes.firstCell}>{testCase.rawCaseUrl}</TableCell>
      <TableCell align="center">
        <VerdictChip verdict={testCase.verdict} />
      </TableCell>
      <TableCell align="center">
        <IconButton onClick={onArchiveClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <ConfirmDialog
        title="Are you sure you want to archive this test case?"
        description={testCase.rawCaseUrl}
        isOpen={isArchiveDialogOpen}
        onCancel={onArchiveCancel}
        onConfirm={onArchiveConfirm}
        confirmText="Archive"
      />
      {errors && errors.map((m) => <Alert severity="error">{m}</Alert>)}
    </TableRow>
  );
}

export function PublicationTestCases({ refetchQueries, publicationId, testCases, ...rest }) {
  const { props: inputProps, values, errors, setErrors, reset } = useForm({
    url: '',
  });

  const [addTestCase] = useMutation(ADD_TEST_CASE_MUTATION, { refetchQueries });
  const onSubmit = async (e) => {
    e.preventDefault();
    const {
      data: {
        createTestCase: { errors },
      },
    } = await addTestCase({
      variables: {
        publicationId: publicationId,
        url: values.url,
      },
    });

    setErrors(errors);
    if (!errors.length) reset();
  };

  return (
    <Box p={2} component={Paper} {...rest}>
      <Typography component="p" variant="h5" children="Test Cases" />
      <Typography component="p" variant="body1" color="textSecondary">
        Test cases are urls of documents that should be collected before a Publication can be
        considered "passing"'
      </Typography>
      <TableContainer
        onSubmit={onSubmit}
        data-testid="PublicationTestCasesForm"
        p={1}
        component="form"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>URL</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testCases.map((testCase) => (
              <TestCaseRow key={testCase.id} testCase={testCase} refetchQueries={refetchQueries} />
            ))}
          </TableBody>
          <TableFooter>
            <AddTestCaseRow inputProps={inputProps} formValues={values} />
          </TableFooter>
        </Table>
      </TableContainer>
      {errors && errors.map((e) => <Alert severity="error">{e}</Alert>)}
    </Box>
  );
}

function PublicationTestCasesWithData({ publicationId, ...rest }) {
  const { data, loading, error } = useQuery(TEST_CASES_QUERY, { variables: { publicationId } });
  const refetchQueries = () => [{ query: TEST_CASES_QUERY, variables: { publicationId } }];

  if (loading) return <LinearProgress data-testid="loading" />;
  if (error)
    return error.graphQLErrors.map((message) => (
      <Alert severity="error">{JSON.stringify(message)}</Alert>
    ));

  return (
    <PublicationTestCases
      publicationId={publicationId}
      testCases={data.testCases}
      refetchQueries={refetchQueries}
      {...rest}
    />
  );
}
export default PublicationTestCasesWithData;
