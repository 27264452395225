import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import ConfirmDialog from '../../ConfirmDialog';
import { SEEDS_QUERY, ARCHIVE_SEED_MUTATION } from '../queries';

const ArchiveSeed = ({ seed, publicationId }) => {
  const refetchQueries = () => [{ query: SEEDS_QUERY, variables: { publicationId } }];

  const [archiveSeed] = useMutation(ARCHIVE_SEED_MUTATION, { refetchQueries });
  const [isOpen, setOpen] = useState(false);

  const onArchiveClick = () => {
    setOpen(true);
  };

  const onArchiveConfirm = useCallback(async () => {
    await archiveSeed({ variables: { id: seed.id } });
    setOpen(false);
  }, [archiveSeed, seed]);

  const onArchiveCancel = () => setOpen(false);

  return (
    <>
      <IconButton onClick={onArchiveClick} children={<DeleteIcon />} />
      <ConfirmDialog
        isOpen={isOpen}
        title="Are you sure you want to delete this seed?"
        onConfirm={onArchiveConfirm}
        onCancel={onArchiveCancel}
      >
        <pre>{JSON.stringify(seed.config, null, 2)}</pre>
      </ConfirmDialog>
    </>
  );
};

ArchiveSeed.propTypes = {
  publicationId: PropTypes.string,
  seed: PropTypes.shape({
    id: PropTypes.string,
    config: PropTypes.object,
  }),
};

export default ArchiveSeed;
