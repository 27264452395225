import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import ArchiveSeed from '../../ArchiveSeed';

function Seed({ isAdvanced, seed, publicationId, ...restProps }) {
  return (
    <TableRow {...restProps}>
      {isAdvanced ? (
        <TableCell colSpan={3}>
          <pre>{JSON.stringify(seed.config, null, 2)}</pre>
        </TableCell>
      ) : (
        <>
          <TableCell>{seed.config.pattern}</TableCell>
          <TableCell align="center">
            <Switch disabled checked={seed.config.html} />
          </TableCell>
          <TableCell align="center">{seed.config.depth}</TableCell>
        </>
      )}
      <TableCell align="right">
        <ArchiveSeed seed={seed} publicationId={publicationId} />
      </TableCell>
    </TableRow>
  );
}

Seed.propTypes = {
  seed: PropTypes.shape({
    id: PropTypes.string,
    publicationId: PropTypes.string,
    config: PropTypes.shape({
      pattern: PropTypes.string,
      depth: PropTypes.number,
      html: PropTypes.bool,
      exclude_css: PropTypes.string,
      limit_css: PropTypes.string,
    }),
  }),
};

export default Seed;
